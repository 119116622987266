@font-face {
  font-family: "Poppins-Regular";
  src: url("./asset/font/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("./asset/font/Poppins/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Dongle";
  src: url("./asset/font/Dongle/Dongle-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins-Regular"
}

a:hover {
  text-decoration:none!important;
}

.carousel{
  color:'blue'
}

.MuiDrawer-paperAnchorBottom {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}